import { Button } from '@mantine/core';
import { variants } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangePasswordModal } from './ChangePasswordModal';

type ChangePasswordProps = {
    className?: string;
};

export const ChangePassword = ({ className }: ChangePasswordProps) => {
    const { t } = useTranslation();
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    return (
        <div className={clsx(className)}>
            <h6 className="text-xl mb-2">{t('password')}</h6>
            <p className="text-text-light">{t('changePasswordMessage')}</p>
            <Button
                className="mr-auto mt-4"
                data-testid="changePassword"
                variant={variants.button.secondary}
                onClick={() => setIsChangePasswordModalOpen(true)}
            >
                {t('changePassword')}
            </Button>
            <ChangePasswordModal
                isOpen={isChangePasswordModalOpen}
                onClose={() => setIsChangePasswordModalOpen(false)}
            />
        </div>
    );
};
