import { Button } from '@mantine/core';
import { variants } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteAccountModal } from './DeleteAccountModal';

type DeleteAccountProps = {
    className?: string;
};

export const DeleteAccount = ({ className }: DeleteAccountProps) => {
    const { t } = useTranslation();
    const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
    return (
        <div className={clsx(className)}>
            <h6 className="text-xl mb-2">{t('deleteAccount')}</h6>
            <p className="text-text-light">{t('deleteAccountMessage')}</p>
            <Button
                className="mr-auto mt-4"
                data-testid="deleteAccount"
                variant={variants.button.secondary}
                onClick={() => setIsDeleteAccountModalOpen(true)}
            >
                {t('deleteAccount')}
            </Button>
            <DeleteAccountModal isOpen={isDeleteAccountModalOpen} onClose={() => setIsDeleteAccountModalOpen(false)} />
        </div>
    );
};
