import { Button, Modal, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { sendTrackingEvent, toastNotifications, variants } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useUpdateCurrentUserPassword, useValidatePassword } from 'api/v3/user/user';
import { HotjarEvents } from 'utils/hotjar';
import { PasswordRequirement } from './PasswordRequirement';

type ChangePasswordModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const changePasswordSchema = z
    .object({
        currentPassword: z.string().min(1, 'isRequired'),
        newPassword: z.string().min(1, 'isRequired'),
        verifyNewPassword: z.string().min(1, 'isRequired')
    })
    .refine(({ newPassword, verifyNewPassword }) => newPassword === verifyNewPassword, {
        message: 'passwordNotEqual',
        path: ['verifyNewPassword']
    });

type ChangePasswordSchema = z.infer<typeof changePasswordSchema>;

const errorSet = new Set([
    'PasswordEmpty',
    'PasswordEqualToPreviousPassword',
    'PasswordIsPwned',
    'PasswordMismatch',
    'PasswordNotMetTheRequirements'
]);
const getPasswordError = (errorArray: string[] | null | undefined): string | undefined => {
    if (errorArray) {
        const result = errorArray.find((error) => errorSet.has(error));
        if (result) {
            return `${result.charAt(0).toLowerCase()}${result.slice(1)}`;
        }
    }
    return undefined;
};

export const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
    const { t } = useTranslation();

    const {
        getInputProps,
        onSubmit,
        getValues,
        errors,
        reset,
        validateField,
        isDirty,
        isTouched,
        isValid: isValidForm
    } = useForm<ChangePasswordSchema>({
        mode: 'controlled',
        validate: zodResolver(changePasswordSchema),
        validateInputOnChange: true,
        initialValues: { currentPassword: '', newPassword: '', verifyNewPassword: '' }
    });

    const { mutateAsync: updateCurrentUserPassword, isPending: isUpdateUserPasswordLoading } =
        useUpdateCurrentUserPassword();
    const {
        mutateAsync: validatePassword,
        data: validatePasswordResponse,
        isPending: isValidatingPassword,
        reset: resetValidatePassword
    } = useValidatePassword();

    const [newPassword, cancelDebounce] = useDebouncedValue(getValues().newPassword, 300);
    const [previousNewPassword, setPreviousNewPassword] = useState(newPassword);

    if (previousNewPassword !== newPassword) {
        setPreviousNewPassword(newPassword);
        if (newPassword) {
            validatePassword({ data: newPassword });
            if (isTouched('verifyNewPassword')) {
                validateField('verifyNewPassword');
            }
        }
    }

    const isValid = isValidForm() && validatePasswordResponse && validatePasswordResponse.data.passwordValid;

    const handleChangePassword = async (data: ChangePasswordSchema) => {
        try {
            await updateCurrentUserPassword({ data });
            sendTrackingEvent(HotjarEvents.PasswordChanged);

            toastNotifications.sucess({ title: t('success'), message: t('dataSaved', { data: t('password') }) });
            handleClose();
        } catch {
            toastNotifications.error({ title: t('failed'), message: t('dataNotSaved', { data: t('password') }) });
        }
    };

    const handleClose = () => {
        cancelDebounce();
        reset();
        resetValidatePassword();
        onClose();
    };

    const passwordError = getPasswordError(validatePasswordResponse?.data.validationErrors);
    const passwordErrorMessage = newPassword && passwordError && t(passwordError);

    return (
        <Modal opened={isOpen} size="lg" title={t('changePassword')} onClose={handleClose}>
            <form className="flex flex-col gap-4" onSubmit={onSubmit(handleChangePassword)}>
                <TextInput
                    label={t('currentPassword')}
                    placeholder={t('currentPasswordPlaceholder')}
                    type="password"
                    {...getInputProps('currentPassword')}
                    error={errors.currentPassword && t(errors.currentPassword.toString())}
                    required
                />
                <div>
                    <TextInput
                        label={t('newPassword')}
                        placeholder={t('newPasswordPlaceholder')}
                        type="password"
                        {...getInputProps('newPassword')}
                        error={passwordErrorMessage}
                        required
                    />
                    <div className="mt-2 flex flex-col gap-1">
                        <PasswordRequirement
                            disabled={!newPassword || isValidatingPassword}
                            fulfills={!validatePasswordResponse?.data.validationErrors?.includes('PasswordTooShort')}
                            requirement={t('passwordTooShort')}
                        />
                        <PasswordRequirement
                            disabled={!newPassword || isValidatingPassword}
                            fulfills={
                                !validatePasswordResponse?.data.validationErrors?.includes('PasswordRequiresUpper')
                            }
                            requirement={t('passwordRequiresUpper')}
                        />
                        <PasswordRequirement
                            disabled={!newPassword || isValidatingPassword}
                            fulfills={
                                !validatePasswordResponse?.data.validationErrors?.includes('PasswordRequiresLower')
                            }
                            requirement={t('passwordRequiresLower')}
                        />
                        <PasswordRequirement
                            disabled={!newPassword || isValidatingPassword}
                            fulfills={
                                !validatePasswordResponse?.data.validationErrors?.includes('PasswordRequiresDigit')
                            }
                            requirement={t('passwordRequiresDigit')}
                        />
                        <PasswordRequirement
                            disabled={!newPassword || isValidatingPassword}
                            fulfills={
                                !validatePasswordResponse?.data.validationErrors?.includes(
                                    'PasswordRequiresNonAlphanumeric'
                                )
                            }
                            requirement={t('passwordRequiresNonAlphanumeric')}
                        />
                        <PasswordRequirement
                            disabled={!newPassword || isValidatingPassword}
                            fulfills={
                                !validatePasswordResponse?.data.validationErrors?.includes(
                                    'PasswordContainsPartsOfUserName'
                                )
                            }
                            requirement={t('passwordContainsPartsOfUserName')}
                        />
                    </div>
                </div>
                <TextInput
                    label={t('verifyNewPassword')}
                    placeholder={t('newPasswordPlaceholder')}
                    type="password"
                    {...getInputProps('verifyNewPassword')}
                    error={errors.verifyNewPassword && t(errors.verifyNewPassword.toString())}
                    required
                />
                <div className="ml-auto mt-2">
                    <Button className="mr-2" variant={variants.button.secondary} onClick={handleClose}>
                        {t('cancel')}
                    </Button>
                    <Button
                        disabled={!isDirty || !isValid}
                        loading={isUpdateUserPasswordLoading || isValidatingPassword}
                        type="submit"
                    >
                        {t('changePassword')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};
