import { MantineThemeOverride } from '@mantine/core';
import { classNames } from '@uag/react-core';
import { clsx } from 'clsx';

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const getCustomThemeOverrides = (): MantineThemeOverride => ({
    components: {
        Table: {
            classNames: {
                table: 'text-left w-full',
                th: clsx`text-base-bold p-2 first:pl-0 last:pr-0`,
                td: clsx`text-base p-2 first:pl-0 last:pr-0`,
                tr: clsx`border-b border-b-border-disabled`
            }
        },
        InputBase: {
            defaultProps: {
                unstyled: true
            },
            classNames: classNames.textField.default
        }
    }
});
