import { Center, Table } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { ItemsPerPageType, Pagination } from '@uag/react-core';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LogEventModel } from 'api/v3/models';
import { useGetCurrentUsersActivityLog } from 'api/v3/user/user';
import { SkeletonTable } from './SkeletonTable';

const getEventDisplayName = (event: LogEventModel) => {
    let displayName = event.eventType
        .split('.')
        .pop() // get last part of the event type
        ?.split(/(?=[A-Z])/) // split by capital letters
        .join(' ') // join with space
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase()) // capitalize first letter
        .replace(' event', '');

    switch (event.eventType.split('.').slice(3).join('.')) {
        case 'User.Events.UserLoginEvent':
            if (event.eventData && event.eventData.Success !== undefined) {
                displayName = event.eventData.Success ? ` ${t('loginSuccessfully')}` : ` ${t('loginFailed')}`;
            }
    }

    return displayName;
};

export const ProfileActivity = () => {
    const { t } = useTranslation();
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [page, setPage] = useState(1);

    const { ref: paginationRef, width: paginationWidth } = useElementSize();

    const { data: userActivities, isLoading: isLoadingUserActivities } = useGetCurrentUsersActivityLog({
        $top: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $includeTotalCount: true
    });

    if (!isLoadingUserActivities && (!userActivities || userActivities.data.length <= 0)) {
        return (
            <Center>
                <p className="p-8">{t('noUserActivity')}</p>
            </Center>
        );
    }
    const totalPages =
        userActivities && userActivities.metadata?.paging?.totalCount
            ? Math.ceil(userActivities.metadata.paging.totalCount / itemsPerPage)
            : 1;

    const rows = userActivities?.data.map((userActivity, index) => {
        return (
            <Table.Tr key={userActivity.eventId} data-testid={`activityRow${index}`}>
                <Table.Td>{dayjs(userActivity.timeGenerated).format('MMM DD, YYYY h:mm A')}</Table.Td>
                <Table.Td>{userActivity.clientName}</Table.Td>
                <Table.Td>{getEventDisplayName(userActivity)}</Table.Td>
            </Table.Tr>
        );
    });

    return (
        <>
            {isLoadingUserActivities ? (
                <SkeletonTable tableRowCount={5} tableRowItems={3} />
            ) : (
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>{t('time')}</Table.Th>
                            <Table.Th>{t('client')}</Table.Th>
                            <Table.Th>{t('action')}</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            )}
            <Pagination
                ref={paginationRef}
                className="mt-8"
                disabled={isLoadingUserActivities}
                itemsPerPage={paginationWidth > 570 ? itemsPerPage : undefined}
                page={page}
                totalPages={totalPages}
                withSkip
                onItemsPerPageChange={setItemsPerPage}
                onPageChange={setPage}
            />
        </>
    );
};
