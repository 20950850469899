import { secureRoute } from '@uag/react-core';
import { Route, Routes } from 'react-router-dom';

import { Home } from './Home';
import { NotFound } from './NotFound';
import { Unauthorized } from './Unauthorized';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route Component={secureRoute(Home)} path="/:tab" />
            <Route Component={secureRoute(Home)} path="/" />
            <Route Component={Unauthorized} path="/unauthorized" />
            <Route Component={NotFound} path="/notFound" />
        </Routes>
    );
};
