const href = window.location.href;

export const getAuthorityUrl = () => {
    if (href.includes('local')) {
        return import.meta.env.VITE_APP_OIDC_AUTHORITY;
    }

    const domainName = getDomainWithoutSubdomain(href);

    return `https://login.${domainName}`;
};

export const getDomainWithoutSubdomain = (url: string) => {
    const urlParts = new URL(url).hostname.split('.');

    return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.');
};
