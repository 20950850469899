import { Avatar, Progress } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { hideFromTracking, sendTrackingEvent, toastNotifications, variants } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailUserModel, TenantLoginProviderType } from 'api/v3/models';
import { getGetCurrentUserDetailsQueryKey, useUploadCurrentUserImage } from 'api/v3/user/user';
import { ImageFileButton } from 'shared/components/ImageFileButton';
import { HotjarEvents } from 'utils/hotjar';

type ProfileHeadProps = {
    user: DetailUserModel;
    className?: string;
};

const emptyValues = new Set(['', null, undefined, 'Undefined', 0]);

export const ProfileHead = ({ user, className }: ProfileHeadProps) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { mutateAsync: uploadCurrentUserImage } = useUploadCurrentUserImage();

    const [isUploadingImage, setIsUploadingImage] = useState(false);

    const isActiveDirectoryUser = user.loginProviderType === TenantLoginProviderType.AzureAD;
    const { firstName, lastName, phoneNumber, mobileNumber, language, profilePictureUris, displayName } = user;

    const userValues = isActiveDirectoryUser
        ? [language]
        : [profilePictureUris.length, firstName, lastName, phoneNumber || mobileNumber, language];
    const emptyValuesCount = userValues.reduce((acc: number, curr) => acc + (emptyValues.has(curr) ? 1 : 0), 0);
    const imageSource =
        profilePictureUris.find((picture) => picture.size === 'Large')?.uri || profilePictureUris[0].uri;

    const handleImageSelected = async (file: File | null) => {
        if (file) {
            setIsUploadingImage(true);
            try {
                await uploadCurrentUserImage({ data: { File: file } });
                await queryClient.invalidateQueries({ queryKey: getGetCurrentUserDetailsQueryKey() });
                toastNotifications.sucess({
                    title: t('success'),
                    message: t('dataSaved', { data: t('profileImage') })
                });
                sendTrackingEvent(HotjarEvents.ProfilePictureChanged);
            } catch {
                toastNotifications.error({
                    title: t('failed'),
                    message: t('dataNotSaved', { data: t('profileImage') })
                });
            }
            setIsUploadingImage(false);
        }
    };

    // prettier-ignore
    const completeValue = Math.round((1 - (emptyValuesCount / userValues.length)) * 100);

    return (
        <div className={clsx(className, 'flex flex-col items-center gap-4 sm:gap-6')}>
            {isActiveDirectoryUser ? (
                <Avatar
                    classNames={{ image: 'h-32 w-32 ' }}
                    src={imageSource}
                    variant={variants.avatar.extraLarge}
                    {...hideFromTracking}
                />
            ) : (
                <ImageFileButton
                    data-testid="userImage"
                    imageSource={imageSource}
                    loading={isUploadingImage}
                    onImageSelected={handleImageSelected}
                />
            )}
            <h3 className="text-2xl xs:text-3xl sm:hidden" data-testid="displayName" {...hideFromTracking}>
                {displayName}
            </h3>
            <div className="flex flex-col items-center gap-1">
                <span className="">{t('yourProfile')}</span>
                <Progress className="w-32" value={completeValue} />
                <span className="text-text-ultraLight text-sm">
                    {completeValue}% {t('complete')}
                </span>
            </div>
        </div>
    );
};
