import { Notification } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ProfileSyncedInformation = () => {
    const { t } = useTranslation();

    return (
        <Notification title={t('profileSynced')} withCloseButton={false}>
            <Trans
                components={[
                    <Link
                        key="externalLink"
                        className="link link-external"
                        target="_blank"
                        to="https://myaccount.microsoft.com/?ref=MeControl"
                    />
                ]}
                i18nKey="profileSyncedMessage"
                ns="translations"
            />
        </Notification>
    );
};
