import { Table } from '@mantine/core';
type Props = {
    tableRowCount?: number;
    tableRowItems?: number;
};
export const SkeletonTable = ({ tableRowCount = 5, tableRowItems = 3 }: Props) => (
    <Table>
        <Table.Thead>
            <Table.Tr>
                {[...Array(tableRowItems)].map((_, index) => (
                    <Table.Th key={index}>
                        <div className="max-w-sm animate-pulse">
                            <div className="bg-gray-200 dark:bg-gray-700 mb-2.5 h-2 w-10 max-w-[360px] basis-1/4 rounded-full bg-background-gray md:w-20" />
                        </div>
                    </Table.Th>
                ))}
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {[...Array(tableRowCount)].map((_, index) => (
                <Table.Tr key={index}>
                    {[...Array(tableRowItems)].map((_, cellIndex) => (
                        <Table.Td key={cellIndex}>
                            <div className="max-w-sm animate-pulse">
                                <div className=" dark:bg-gray-700 mb-2.5 h-2 w-20 max-w-[360px] basis-1/4 rounded-full bg-background-gray-light md:w-40" />
                            </div>
                        </Table.Td>
                    ))}
                </Table.Tr>
            ))}
        </Table.Tbody>
    </Table>
);
