import { Loader } from '@mantine/core';
import { ErrorView, variants } from '@uag/react-core';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCurrentUserDetails } from 'api/v3/user/user';
import { ProfileHead } from './ProfileHead';
import { ProfileTab } from './ProfileTab';

type ProfileProps = {
    className?: string;
};

export const ProfileShell = ({ className }: ProfileProps) => {
    const { i18n } = useTranslation();
    const { data: currentUser, isLoading: isUserLoading, error } = useGetCurrentUserDetails();

    useEffect(() => {
        if (currentUser?.data.language && currentUser.data.language !== i18n.language) {
            i18n.changeLanguage(currentUser.data.language);
        }
    }, [currentUser, currentUser?.data.language, i18n, i18n.language]);

    if (isUserLoading) {
        return <Loader variant={variants.loader.loadingAnimation} />;
    }
    if (error) {
        return <ErrorView description={error.message} title={error.name} />;
    }
    if (!currentUser) {
        return null;
    }
    const user = currentUser.data;

    return (
        <div className={clsx(className, 'gap grid grid-cols-4 gap-12 sm:gap-4')}>
            <ProfileHead className="col-span-4 sm:col-span-1" user={user} />
            <ProfileTab className="col-span-4 sm:col-span-2" user={user} />
        </div>
    );
};
