import { clsx } from 'clsx';

type PasswordRequirementProps = {
    requirement: string;
    fulfills: boolean;
    disabled: boolean;
};

export const PasswordRequirement = ({ requirement, fulfills, disabled }: PasswordRequirementProps) => {
    const fulfilled = !disabled && fulfills;
    const notFulfilled = !disabled && !fulfills;

    return (
        <div
            className={clsx(
                'text-sm flex items-center gap-1',
                disabled && 'text-text-ultraLight',
                fulfilled && 'text-text-success',
                notFulfilled && 'text-text-alert'
            )}
        >
            {disabled && <span className="icon-lg">circle</span>}
            {fulfilled && <span className="icon-lg">check_circle</span>}
            {notFulfilled && <span className="icon-lg">cancel</span>}
            {requirement}
        </div>
    );
};
