import { Divider } from '@mantine/core';

import { DetailUserModel, TenantLoginProviderType } from 'api/v3/models';
import { ChangePassword } from './changePassword/ChangePassword';
import { DeleteAccount } from './deleteAccount/DeleteAccount';
import { ProfileForm } from './ProfileForm';
import { ProfileSyncedInformation } from './ProfileSyncedInformation';

type ProfileContentProps = {
    user: DetailUserModel;
};

export const Profile = ({ user }: ProfileContentProps) => {
    const isActiveDirectoryUser = user.loginProviderType === TenantLoginProviderType.AzureAD;
    return (
        <div className="flex flex-col gap-8">
            {isActiveDirectoryUser && <ProfileSyncedInformation />}
            <ProfileForm user={user} />
            {!isActiveDirectoryUser && (
                <>
                    <Divider />
                    <ChangePassword />
                </>
            )}
            <Divider />
            <DeleteAccount />
        </div>
    );
};
