import { useOidc, useOidcUser } from '@axa-fr/react-oidc';
import { BaseLayout, Notifications, OidcProvider } from '@uag/react-core';

import { useGetInboxToken } from 'api/notifications/inbox/inbox';
import { ProfileShell } from 'profile/shell/ProfileShell';
import { getAuthorityUrl } from './urlHelpers';

const knockConfiguration = {
    apiKey: import.meta.env.VITE_KNOCK_PUBLIC_API_KEY,
    feedId: import.meta.env.VITE_KNOCK_FEED_CHANNEL_ID
};

export const Home = () => {
    const { logout, login } = useOidc();
    const { oidcUser, oidcUserLoadingState } = useOidcUser();
    const { data: inboxToken, isLoading: isInboxTokenLoading } = useGetInboxToken({
        query: { enabled: !!import.meta.env.VITE_APP_NOTIFICATIONS_API_URL }
    });

    return (
        <OidcProvider
            authority={getAuthorityUrl()}
            login={login}
            logout={logout}
            user={oidcUser}
            userLoadingState={oidcUserLoadingState}
        >
            <BaseLayout
                innerClassName="flex-grow overflow-auto text-base pb-8 pt-8 flex flex-col gap-8 text-text-default xs:pt-12 sm:pt-16"
                versionNumber={APP_VERSION}
            >
                <Notifications
                    isLoading={isInboxTokenLoading}
                    userId={inboxToken?.data?.userIdentifier}
                    userToken={inboxToken?.data?.token}
                    {...knockConfiguration}
                />
                <ProfileShell className="px-2 sm:px-6" />
            </BaseLayout>
        </OidcProvider>
    );
};
