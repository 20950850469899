import i18next, { ParseKeys, use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import bg from 'translations/bg.json';
import cs from 'translations/cs.json';
import da from 'translations/da.json';
import de from 'translations/de.json';
import el from 'translations/el.json';
import en from 'translations/en.json';
import es from 'translations/es.json';
import et from 'translations/et.json';
import fi from 'translations/fi.json';
import fr from 'translations/fr.json';
import hr from 'translations/hr.json';
import hu from 'translations/hu.json';
import it from 'translations/it.json';
import lt from 'translations/lt.json';
import lv from 'translations/lv.json';
import nb from 'translations/nb.json';
import nl from 'translations/nl.json';
import pl from 'translations/pl.json';
import pt from 'translations/pt.json';
import ro from 'translations/ro.json';
import ru from 'translations/ru.json';
import sk from 'translations/sk.json';
import sl from 'translations/sl.json';
import sr from 'translations/sr.json';
import sv from 'translations/sv.json';
import tr from 'translations/tr.json';
import uk from 'translations/uk.json';

const getVariationOfAOrAn = (value: string, capitalize: boolean) => {
    const letters = ['A', 'E', 'I', 'O', 'U', 'H', 'a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = value.substring(0, 1);
    if (
        letters.find(function (l) {
            return firstLetter === l;
        })
    ) {
        return capitalize ? 'An' : 'an';
    }
    return capitalize ? 'A' : 'a';
};

export const defaultNS = 'translations';

export const resources = {
    'bg': { Name: 'Български', translations: bg },
    'cs': { Name: 'Čeština', translations: cs },
    'da': { Name: 'Dansk', translations: da },
    'de': { Name: 'Deutsch', translations: de },
    'el': { Name: 'Ελληνικά', translations: el },
    'en-US': { Name: 'English (US)', translations: en },
    'en-GB': { Name: 'English (UK)', translations: en },
    'es': { Name: 'Español', translations: es },
    'es-US': { Name: 'Español (Estados Unidos)', translations: es },
    'et': { Name: 'Eesti', translations: et },
    'fi': { Name: 'Suomi', translations: fi },
    'fr': { Name: 'French', translations: fr },
    'hr': { Name: 'Hrvatski', translations: hr },
    'hu': { Name: 'Magyar', translations: hu },
    'it': { Name: 'Italiano', translations: it },
    'lt': { Name: 'Lietuvių', translations: lt },
    'lv': { Name: 'Latviešu', translations: lv },
    'nb': { Name: 'Norsk (Bokmål)', translations: nb },
    'nl': { Name: 'Nederlands', translations: nl },
    'pl': { Name: 'Polski', translations: pl },
    'pt': { Name: 'Português', translations: pt },
    'ro': { Name: 'Română', translations: ro },
    'ru': { Name: 'Русский', translations: ru },
    'sk': { Name: 'Slovenčina', translations: sk },
    'sl': { Name: 'Slovenščina', translations: sl },
    'sr': { Name: 'Српски', translations: sr },
    'sv': { Name: 'Svenska', translations: sv },
    'tr': { Name: 'Türkçe', translations: tr },
    'uk': { Name: 'Українська', translations: uk }
};

// eslint-disable-next-line react-hooks/rules-of-hooks
use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: ['en'],
        detection: {
            order: ['cookie', 'localStorage', 'navigator'],
            caches: ['cookie', 'localStorage']
        },
        defaultNS,
        resources,
        returnNull: false,
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (format === 'uppercase') {
                    return value.toUpperCase();
                }
                if (format === 'lowercase') {
                    return value.toLowerCase();
                }
                if (format === 'en-handle-an') {
                    return !lng || lng === 'en' ? getVariationOfAOrAn(value, false) : '';
                }
                if (format === 'en-handle-an-capitalized') {
                    return !lng || lng === 'en' ? getVariationOfAOrAn(value, true) : '';
                }
                if (format === 'en-handle-lowercase') {
                    return !lng || lng === 'en' ? value.toLowerCase() : value;
                }
                return value;
            }
        }
    });

export type TranslationKeys = ParseKeys<'translations'>;

export const i18n = i18next;
